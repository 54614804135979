import { BackgroundGrid } from 'components/core/BackgroundGrid'
import { PrimaryButton } from 'components/core/Buttons'
import { HeaderSpacer } from 'components/core/header/Header'
import { ContentWrapper } from 'components/core/layout/Common'
import { PageView } from 'components/core/layout/FeaturedItemPage'
import { Spacer } from 'components/core/Spacer'
import { H1Response, H2Responsive } from 'components/core/Typography'
import useIsMobile from 'hooks/util/useIsMobile'
import Link from 'next/link'
import styled from 'styled-components'
import { cdnRoot } from 'utils/cdn'

const black = '#080A0C'

// pages/404.js
export default function Custom404() {
  const isMobile = useIsMobile()
  return (
    <PageView
      style={{
        background: '#EBF787',
        // background: 'linear-gradient(270deg, rgba(235, 247, 135, 0.4) 0%, #EBF787 17.19%, #EBF787 82.81%, rgba(235, 247, 135, 0.4) 100%)',
        position: 'relative',
      }}
    >
      {isMobile ? null : <BackgroundGrid />}

      <ContentWrapper
        style={{
          color: black,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          minHeight: '100vh',
          maxWidth: 1280,
          flex: 1,
        }}
      >
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <div
            style={{
              position: 'absolute',
              left: -200,
              right: -200,
              bottom: 0,
              top: 0,
              zIndex: 0,
              background:
                'linear-gradient(270deg, rgba(235, 247, 135, 0.4) 0%, #EBF787 17.19%, #EBF787 82.81%, rgba(235, 247, 135, 0.4) 100%)',
            }}
          ></div>
          <div
            style={{
              position: 'relative',
              zIndex: 1,
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <HeaderSpacer />
            <LayoutContainer>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <H1404>404</H1404>
                <H2>Page not found</H2>
                <Spacer size={32} />
                <Link
                  passHref
                  href={{
                    pathname: '/',
                  }}
                >
                  <PrimaryButton
                    style={{
                      maxWidth: 260,
                    }}
                    buttonType={'success'}
                    as={'a'}
                  >
                    Back to home
                  </PrimaryButton>
                </Link>
              </div>
              <div>
                <img
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    height: isMobile ? 300 : 550,
                  }}
                  src={`${cdnRoot}/images/nedrina-with-gradient.png`}
                />
              </div>
            </LayoutContainer>
          </div>
        </div>
      </ContentWrapper>
    </PageView>
  )
}

const LayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 955px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
        display: flex;
        flex-direction: column;
        align-items: center;
  `}
`

const H1404 = styled(H1Response)`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 110%;
  /* identical to box height, or 132px */

  letter-spacing: -0.03em;

  /* Basic/Black */

  color: #080a0c;
`

const H2 = styled(H2Responsive)`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  /* identical to box height, or 44px */

  letter-spacing: -0.03em;

  /* Basic/Black */

  color: #080a0c;
`
