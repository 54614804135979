import React from 'react'
import styled from 'styled-components'
import range from 'lodash/range'

const BgGrid = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  opacity: 0.5;
  bottom: -400px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(120px, 1fr));

  & > * {
    /* background: rgba(0,0,0,0.1); */
    border-bottom: 2px solid #fac74f;
    border-right: 2px solid #fac74f;
  }
`
export const BackgroundGrid = React.memo(() => {
  return (
    <BgGrid
      style={{
        position: 'absolute',
      }}
    >
      {range(1000).map((_) => {
        return <div key={_}></div>
      })}
    </BgGrid>
  )
})

BackgroundGrid.displayName = 'BackgroundGrid'
